import {
  FaSnapchat,
  FaWhatsapp,
  FaPhoneAlt,
} from 'react-icons/fa'
import ReciveFromStore from '../components/ReciveFromStore'
import TableNumber from '../components/TableNumber'
import Transport from '../components/Transport'

export const sideNavData = [
  {
    id: 1,
    text: 'سناب شات',
    icon: FaSnapchat,
    href: 'https://www.snapchat.com/add/Ot-caffe',
  },
  {
    id: 2,
    text: 'واتساب',
    icon: FaWhatsapp,
    href: 'https://api.whatsapp.com/send/?phone=966539706776',
  },
  { id: 3, text: 'اتصل بنا', icon: FaPhoneAlt, href: 'tel:0539706776' },
]
export const restaurants = [
  {
    id: 1,
    name: 'أوشن تري كوفي',
    distance: 5,
    addrress: ' شارع صهيب بن سنان، نجران , المملكة العربية السعودية',
    open: true,
    open24h: true,
    searchQuery: 'شارع صهيب بن سنان ، نجران',
    coords: { lat: 17.579716, lng: 44.227906 },
  },
]
export const data = [
  {
    id: 1,
    title: 'القهوة المختصة',
    image: '../../../img/list/mo5.png',
    selected: true,
    items: [
      {
        id: 1,
        title: 'حارV60',
        description: '',
        image: '../../img/food/في.jpg',
        price: 15,
        calories: 0,
        selected: true,
      },
      {
        id: 2,
        title: 'كيمكس حار',
        description: '',
        image: '../../img/food/كيم.jpg',
        price: 15,
        calories: 0,
        selected: true,
      },
      {
        id: 3,
        title: 'كيمكس بارد',
        description: '',
        image: '../../img/food/كيمك.jpg',
        price: 16,
        calories: 0,
        selected: true,
      },
      {
        id: 4,
        title: 'ايس دريب',
        description: '',
        image: '../../img/food/دريب.jpg',
        price: 16,
        calories: 0,
        selected: true,
      },
      {
        id: 5,
        title: 'امريكانو',
        description: '',
        image: '../../img/food/امريكا.jpg',
        price: 8,
        calories: 0,
        selected: true,
      },
      {
        id: 6,
        title: 'ايس امريكانو',
        description: '',
        image: '../../img/food/امري.jpg',
        price: 8,
        calories: 0,
        selected: true,
      },
    ],
  },
  {
    id: 2,
    title: 'القهوة الساخنة',
    image: '../../../img/list/coffe-cup.png',
    selected: false,
    items: [
      {
        id: 7,
        title: 'قهوة اليوم',
        description: '',
        image: '../../img/food/اليوم.jpg',
        price: 8,
        calories: 0,
        selected: true,
      },
      {
        id: 8,
        title: 'كورتادو',
        description: '',
        image: '../../img/food/كورتادو.jpg',
        price: 10,
        calories: 0,
        selected: true,
      },
      {
        id: 9,
        title: 'فلات وايت',
        description: '',
        image: '../../img/food/فلات.jpg',
        price: 13,
        calories: 0,
        selected: true,
      },
      {
        id: 10,
        title: 'كابتشينو',
        description: '',
        image: '../../img/food/كابتشينو.jpg',
        price: 11,
        calories: 0,
        selected: true,
      },
      {
        id: 11,
        title: 'لاتيه',
        description: '',
        image: '../../img/food/لاتيه.jpg',
        price: 9,
        calories: 0,
        selected: true,
      },
      {
        id: 12,
        title: 'سبانش لاتيه',
        description: '',
        image: '../../img/food/سبانش.jpg',
        price: 14,
        calories: 0,
        selected: true,
      },
      {
        id: 13,
        title: 'لوتس لاتيه',
        description: '',
        image: '../../img/food/لوتسلاتيه.jpg',
        price: 15,
        calories: 0,
        selected: true,
      },
      {
        id: 14,
        title: 'مكياتو',
        description: '',
        image: '../../img/food/مكياتو.jpg',
        price: 8,
        calories: 0,
        selected: true,
      },
      {
        id: 15,
        title: 'اسبريسو دبل',
        description: '',
        image: '../../img/food/اسبريسو.jpg',
        price: 12,
        calories: 0,
        selected: true,
      },
      {
        id: 16,
        title: 'هوت شوكلت',
        description: '',
        image: '../../img/food/هوت.jpg',
        price: 13,
        calories: 0,
        selected: true,
      },
      {
        id: 17,
        title: 'موكا',
        description: '',
        image: '../../img/food/موكا.jpg',
        price: 15,
        calories: 0,
        selected: true,
      },
      {
        id: 18,
        title: 'قهوة فرنسية بالبندق',
        description: '',
        image: '../../img/food/فرنسيب.jpg',
        price: 12,
        calories: 0,
        selected: true,
      },
      {
        id: 19,
        title: 'قهوة فرنسيه فانيليا',
        description: '',
        image: '../../img/food/فرنسي.jpg',
        price: 11,
        calories: 0,
        selected: true,
      },
      {
        id: 20,
        title: 'قهوة تركية',
        description: '',
        image: '../../img/food/تركي.jpg',
        price: 8,
        calories: 0,
        selected: true,
      },
      {
        id: 21,
        title: 'قهوة تركي بالحليب',
        description: '',
        image: '../../img/food/تركيحليب.jpg',
        price: 9,
        calories: 0,
        selected: true,
      },
      {
        id: 22,
        title: 'دله قهوة سعودية',
        description: '',
        image: '../../img/food/عربي.jpg',
        price: 15,
        calories: 0,
        selected: true,
      },
    ],
    
  },
  {
    id: 3,
    title: 'القهوة الباردة',
    image: '../../../img/list/bard.png',
    selected: true,
    items: [
      {
        id: 24,
        title: 'ايس لاتيه',
        description: '',
        image: '../../img/food/ايسلاتيه.jpg',
        price: 13,
        calories: 0,
        selected: true,
      },
      {
        id: 25,
        title: 'ايس سبانش لاتيه',
        description: '',
        image: '../../img/food/ايسسبانش.jpg',
        price: 15,
        calories: 0,
        selected: true,
      },
      {
        id: 26,
        title: 'ايس لوتس لاتيه',
        description: '',
        image: '../../img/food/ايسلوتس.jpg',
        price: 15,
        calories: 0,
        selected: true,
      },
      {
        id: 27,
        title: 'ايس بستاشيو لاتيه',
        description: '',
        image: '../../img/food/بستاشيو.jpg',
        price: 16,
        calories: 0,
        selected: true,
      },
      {
        id: 28,
        title: 'ايس وايت موكا',
        description: '',
        image: '../../img/food/وايت.jpg',
        price: 16,
        calories: 0,
        selected: true,
      },
      {
        id: 29,
        title: 'ايس كراميل لاتيه',
        description: '',
        image: '../../img/food/كراميل.jpg',
        price: 16,
        calories: 0,
        selected: true,
      },
      {
        id: 30,
        title: 'ايس شوكلت',
        description: '',
        image: '../../img/food/ايسشوكلت.jpg',
        price: 14,
        calories: 0,
        selected: true,
      },
      {
        id: 31,
        title: 'فرابتشينو كلاسك',
        description: '',
        image: '../../img/food/فرابتشينو.jpg',
        price: 18,
        calories: 0,
        selected: true,
      },
      {
        id: 32,
        title: 'فرابتشينو',
        description: 'شوكلاتة - فانيليا',
        image: '../../img/food/فرابتشينو.jpg',
        price: 19,
        calories: 0,
        selected: true,
        extras: [
          { name: 'شوكلاتة', price: 0 },
          { name: 'فانيليا', price: 0 },
        ],
      },
      {
        id: 33,
        title: 'ايس تشيكن وايت موكا',
        description: '',
        image: '../../img/food/تشكن.jpg',
        price: 18,
        calories: 0,
        selected: true,
      },
    ],
  },
  {
    id: 4,
    title: 'الشاي',
    image: '../../../img/list/tea.png',
    selected: false,
    items: [
      {
        id: 34,
        title: 'كوب شاي احمر',
        description: '',
        image: '../../img/food/كوباحمر.jpg',
        price: 3,
        calories: 0,
        selected: true,
      },
      {
        id: 35,
        title: 'براد شاي احمر',
        description: '',
        image: '../../img/food/براد.jpg',
        price: 12,
        calories: 0,
        selected: true,
      },
      {
        id: 36,
        title: 'كوب شاي مغربي',
        description: '',
        image: '../../img/food/كوبمغربي.jpg',
        price: 4,
        calories: 0,
        selected: true,
      },
      {
        id: 37,
        title: 'براد شاي مغربي',
        description: '',
        image: '../../img/food/برادمغربي.jpg',
        price: 16,
        calories: 0,
        selected: true,
      },
      {
        id: 38,
        title: 'شاي كرك',
        description: '',
        image: '../../img/food/كرك.jpg',
        price: 5,
        calories: 0,
        selected: true,
      },
      {
        id: 39,
        title: 'شاي اعشاب طبيعية',
        description: 'نعناع - قرفة - زنجبيل - ليمون - عسل.',
        image: '../../img/food/اعشاب.jpg',
        price: 5,
        calories: 0,
        selected: true,
      },
      {
        id: 40,
        title: 'كوب ليمون',
        description: '',
        image: '../../img/food/ليمون2.jpg',
        price: 4,
        calories: 0,
        selected: true,
      },
      {
        id: 41,
        title: 'كوب زنجبيل',
        description: '',
        image: '../../img/food/زنجبيل.jpg',
        price: 4,
        calories: 0,
        selected: true,
      },
      {
        id: 42,
        title: 'كوب كركدية',
        description: '',
        image: '../../img/food/كركديه.jpg',
        price: 6,
        calories: 0,
        selected: true,
      },
      {
        id: 43,
        title: 'كوب سحلب',
        description: '',
        image: '../../img/food/سحلب.jpg',
        price: 10,
        calories: 0,
        selected: true,
      },
    ],
  },
  {
    id: 5,
    title: 'الحلا',
    image: '../../../img/list/soit.png',
    selected: false,
    items: [
      {
        id: 44,
        title: 'كريب فوتتشيني رول',
        description: '',
        image: '../../img/food/كريب.jpg',
        price: 18,
        calories: 0,
        selected: true,
      },
      {
        id: 45,
        title: 'وافل',
        description: '',
        image: '../../img/food/وافل.jpg',
        price: 15,
        calories: 0,
        selected: true,
      },
      {
        id: 46,
        title: 'ميني بان كيك',
        description: '',
        image: '../../img/food/بان.jpg',
        price: 15,
        calories: 0,
        selected: true,
      },
      {
        id: 47,
        title: 'كيكة الشوكلاتة',
        description: '',
        image: '../../img/food/كيك.jpg',
        price: 15,
        calories: 0,
        selected: true,
      },
      {
        id: 48,
        title: 'تراميسيو',
        description: '',
        image: '../../img/food/تراميسو.jpg',
        price: 20,
        calories: 0,
        selected: true,
      },
      {
        id: 49,
        title: 'كيكة الزعفران',
        description: '',
        image: '../../img/food/زعفران.jpg',
        price: 20,
        calories: 0,
        selected: true,
      },
      {
        id: 50,
        title: 'سان سباستيان',
        description: '',
        image: '../../img/food/سان.jpg',
        price: 20,
        calories: 0,
        selected: true,
      },
      {
        id: 51,
        title: 'تشيز كيك',
        description: 'فراوله - لوتس - أوريو',
        image: '../../img/food/لوتس.jpg',
        price: 20,
        calories: 0,
        selected: true,
        extras: [
          { name: 'فراولة', price: 0 },
          { name: 'لوتس', price: 0 },
          { name: 'أوريو', price: 0 },
        ],
      },
      {
        id: 52,
        title: 'ميني تورن بسكويت',
        description: '',
        image: '../../img/food/تورن.jpg',
        price: 12,
        calories: 0,
        selected: true,
      },
      {
        id: 53,
        title: 'فرنش توست',
        description: '',
        image: '../../img/food/توست.jpg',
        price: 15,
        calories: 0,
        selected: true,
      },
    ],
  },
  {
    id: 6,
    title: 'الموهيتو والمشروبات',
    image: '../../../img/list/moheto5a9.png',
    selected: false,
    items: [
      {
        id: 54,
        title: 'موهيتو نكهات  كود رد',
        description: 'فراولة - رمان - بطيخ - بلو بيري - ريد بيري - اناناس - بندق - فانيليا',
        image: '../../img/food/specialmohito.jpeg',
        price: 15,
        calories: 0,
        selected: true,
        extras: [
          { name: 'فراولة', price: 0 },
          { name: 'رمان', price: 0 },
          { name: 'بطيخ', price: 0 },
          { name: 'بلو بيري', price: 0 },
          { name: 'ريد بيري', price: 0 },
          { name: 'أناناس', price: 0 },
          { name: 'بندق', price: 0 },
          { name: 'فانيليا', price: 0 },
        ],
      },
      {
        id: 55,
        title: 'موهيتو نكهات سفن اب',
        description: 'فراولة - رمان - بطيخ - بلو بيري - ريد بيري - اناناس - بندق - فانيليا',
        image: '../../img/food/redperry.jpg',
        price: 13,
        calories: 0,
        selected: true,
        extras: [
          { name: 'فراولة', price: 0 },
          { name: 'رمان', price: 0 },
          { name: 'بطيخ', price: 0 },
          { name: 'بلو بيري', price: 0 },
          { name: 'ريد بيري', price: 0 },
          { name: 'أناناس', price: 0 },
          { name: 'بندق', price: 0 },
          { name: 'فانيليا', price: 0 },
        ],
      },
      {
        id: 56,
        title: 'موهيتو كلاسك كود رد',
        description: '',
        image: '../../img/food/موهيتومكس-كودرد.jpg',
        price: 15,
        calories: 0,
        selected: true,
      },
      {
        id: 57,
        title: 'موهيتو كلاسيك سفن اب',
        description: '',
        image: '../../img/food/moh-sfn.jpg',
        price: 13,
        calories: 0,
        selected: true,
      },
      {
        id: 58,
        title: 'موهيتو مكس كود رد',
        description: '',
        image: '../../img/food/مموهيتومكس.jpg',
        price: 17,
        calories: 0,
        selected: true,
      },
      {
        id: 59,
        title: 'موهيتو مكس سفن اب',
        description: '',
        image: '../../img/food/موهيتو-سفن.jpg',
        price: 15,
        calories: 0,
        selected: true,
      },
      {
        id: 63,
        title: 'بيبسي',
        description: '',
        image: '../../img/food/pebsi.jpg',
        price: 3,
        calories: 0,
        selected: true,
      },
      {
        id: 64,
        title: 'سفن اب',
        description: '',
        image: '../../img/food/sfn.jpeg',
        price: 3,
        calories: 0,
        selected: true,
      },
      {
        id: 65,
        title: 'ماء',
        description: '',
        image: '../../img/food/ber.png',
        price: 1,
        calories: 0,
        selected: true,
      },
    ],
  },
  {
    id: 7,
    title: 'الميلك شيك',
    image: '../../../img/list/iced-coffee.png',
    selected: false,
    items: [
      {
        id: 60,
        title: 'ميلك شيك كلاسيك فانيليا',
        description: 'بندق - أوريو - لوتس',
        image: '../../img/food/ميلك.jpg',
        price: 15,
        calories: 0,
        selected: true,
        extras: [
          { name: 'بندق', price: 0 },
          { name: 'أوريو', price: 0 },
          { name: 'لوتس', price: 0 },
        ],
      },
      {
        id: 61,
        title: 'ميلك شيك كلاسيك شوكلت',
        description: 'بندق - أوريو - لوتس',
        image: '../../img/food/ميلكشيك.jpg',
        price: 15,
        calories: 0,
        selected: true,
        extras: [
          { name: 'بندق', price: 0 },
          { name: 'أوريو', price: 0 },
          { name: 'لوتس', price: 0 },
        ],
      },
      {
        id: 62,
        title: 'ميلك شيك',
        description: 'بندق - أوريو - لوتس',
        image: '../../img/food/icemoca.jpg',
        price: 17,
        calories: 0,
        selected: true,
        extras: [
          { name: 'بندق', price: 0 },
          { name: 'أوريو', price: 0 },
          { name: 'لوتس', price: 0 },
        ],
      },
    ],
  },
  {
    id: 8,
    title: 'العصائر',
    image: '../../../img/list/drink.png',
    selected: false,
    items: [
      {
        id: 66,
        title: 'مانجو',
        description: '',
        image: '../../img/food/مانجو.jpg',
        price: 14,
        calories: 0,
        selected: true,
      },
      {
        id: 67,
        title: 'فراولة',
        description: '',
        image: '../../img/food/فراوله.jpg',
        price: 14,
        calories: 0,
        selected: true,
      },
      {
        id: 68,
        title: 'جوافة',
        description: '',
        image: '../../img/food/جوافه.jpg',
        price: 14,
        calories: 0,
        selected: true,
      },
      {
        id: 69,
        title: 'كوكتيل',
        description: '',
        image: '../../img/food/كوكتيل.jpg',
        price: 14,
        calories: 0,
        selected: true,
      },
      {
        id: 70,
        title: 'برتقال',
        description: '',
        image: '../../img/food/برتقال.jpg',
        price: 14,
        calories: 0,
        selected: true,
      },
      {
        id: 71,
        title: 'ليمون نعناع',
        description: '',
        image: '../../img/food/ليمون.jpg',
        price: 14,
        calories: 0,
        selected: true,
      },
      {
        id: 72,
        title: 'افوكادو',
        description: '',
        image: '../../img/food/افوكا.jpg',
        price: 14,
        calories: 0,
        selected: true,
      },
      {
        id: 73,
        title: 'موز بالحليب',
        description: '',
        image: '../../img/food/موز.jpg',
        price: 14,
        calories: 0,
        selected: true,
      },
    ],
  },
  {
    id: 9,
    title: 'المخبوزات',
    image: '../../../img/list/croissant.png',
    selected: false,
    items: [
      {
        id: 74,
        title: 'كرواسان جبنة',
        description: '',
        image: '../../img/food/جبن.jpg',
        price: 8,
        calories: 0,
        selected: true,
      },
      {
        id: 75,
        title: 'كرواسان شوكلاتة',
        description: '',
        image: '../../img/food/كرواسان.jpg',
        price: 8,
        calories: 0,
        selected: true,
      },
      {
        id: 76,
        title: 'كرواسان زعتر',
        description: '',
        image: '../../img/food/زعتر.jpg',
        price: 8,
        calories: 0,
        selected: true,
      },
      {
        id: 77,
        title: 'ميني كوكيز شوكلت',
        description: '3 حبات',
        image: '../../img/food/كوكيز.jpg',
        price: 8,
        calories: 0,
        selected: true,
      },
      {
        id: 78,
        title: 'ميني كوكيز وايت',
        description: '3 حبات',
        image: '../../img/food/كوكي.jpg',
        price: 8,
        calories: 0,
        selected: true,
      },
    ],
  },
  {
    id: 10,
    title: 'الماكاولات',
    image: '../../../img/list/pizza.png',
    selected: false,
    items: [
      {
        id: 79,
        title: 'بيتزا خضار',
        description: '',
        image: '../../img/food/بيتزا.jpg',
        price: 22,
        calories: 0,
        selected: true,
      },
      {
        id: 80,
        title: 'بطاطس مقلية',
        description: '',
        image: '../../img/food/بطاطس.jpg',
        price: 7,
        calories: 0,
        selected: true,
      },
      {
        id: 81,
        title: 'وجبة برجر',
        description: '',
        image: '../../img/food/برجر.jpg',
        price: 24,
        calories: 0,
        selected: true,
      },
      {
        id: 82,
        title: 'وجبة افطار',
        description: 'فول -فلافل -  بيض - جبنة مالحة - طحينه - خبز + مشروب',
        image: '../../img/food/فطور.jpg',
        price: 28,
        calories: 0,
        selected: true,
      },
      {
        id: 83,
        title: 'سلطة يونانيه',
        description: '',
        image: '../../img/food/يوناني.jpg',
        price: 12,
        calories: 0,
        selected: true,
      },
      {
        id: 84,
        title: 'سلطة فتوش',
        description: '',
        image: '../../img/food/فتوش.jpg',
        price: 12,
        calories: 0,
        selected: true,
      },
      {
        id: 85,
        title: 'بوكس الشواء',
        description: '',
        image: '../../img/food/بوكس.jpg',
        price: 55,
        calories: 0,
        selected: true,
      },
    ],
  },
]
export const deliveryOptions = [
  {
    id: 1,
    component: Transport,
    name: 'توصيل',
    img: '../../img/delivery/delivery.png',
  },
  {
    id: 2,
    component: ReciveFromStore,
    name: 'إستلام',
    img: '../../img/delivery/pickup.png',
  },
  {
    id: 3,
    component: TableNumber,
    name: 'إختيار الطاولة',
    img: '../../img/delivery/number.png',
  },
]
