import { useEffect, useState } from 'react'
import Modal from './Modal'
import { MdLocationOn, MdOutlinePlace } from 'react-icons/md'
import { AiOutlineUser } from 'react-icons/ai'
import { RiWhatsappFill } from 'react-icons/ri'
import { toast } from 'react-toastify'
import { IoIosArrowBack } from 'react-icons/io'
import logo_light from '../img/logo.png'
import logo_dark from '../img/logo2.png'
import { Link, useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../context'
import MyGoogleMap from './GoogleMap'

const Transport = () => {
  const [formErrors, setFormErrors] = useState({})
  const [, setFormData] = useState({})
  const [userLatLng, setUserLatLng] = useState({ lat: 0, lng: 0 })
  const [modalOn, setModalOn] = useState(false)
  const [fixedCenter, setFixedCenter] = useState()

  const navigate = useNavigate()
  const { cartData, setCartData, percentage } = useGlobalContext()

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        if (location) {
          const { latitude, longitude } = location.coords
          setUserLatLng({ lat: latitude, lng: longitude })
        } else {
          alert('قم بالسماح بتحديد الموقع لتحديد موقعك آلياً')
        }
      })
    }
  }, [])

  // Restaurant Location
  const resLatLng = { lat: 24.621052, lng: 46.54662 }

  // compare distance between restauran and user
  const calcCrow = (lat1, lon1, lat2, lon2) => {
    var R = 6371 // km
    var dLat = toRad(lat2 - lat1)
    var dLon = toRad(lon2 - lon1)
    var latitude1 = toRad(lat1)
    var latitude2 = toRad(lat2)

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(latitude1) *
        Math.cos(latitude2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c
    return d
  }
  const toRad = (Value) => {
    return (Value * Math.PI) / 180
  }

  const validate = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'هذا الحقل مطلوب'
    }
    if (!values.phone) {
      errors.phone = 'هذا الحقل مطلوب'
    }
    if (!values.address) {
      errors.address = 'هذا الحقل مطلوب'
    }
    return errors
  }

  let orderNumber = 1

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      name: e.target.name.value,
      phone: e.target.phone.value,
      address: e.target.address.value,
    }
    setFormErrors(validate(data))
    setFormData(data)
    e.target.reset()
    const distance = calcCrow(
      resLatLng.lat, // restaurant latitude
      resLatLng.lng, // restaurant longitude
      fixedCenter ? fixedCenter?.lat : userLatLng.lat,
      fixedCenter ? fixedCenter?.lng : userLatLng.lng
    )

    // Handle Message
    const deliveryFee = distance > 10 ? 10 : 0 // سعر رسوم التوصيل
    const total = cartData
      .reduce((acc, item) => acc + item.price, 0)
      .toLocaleString('en-US')
    const date = new Date(Date.now())
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let ampm = hours >= 12 ? 'مساءً' : 'صباحاً'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    const time = `${hours}:${minutes} ${ampm}`

    const orderTotal =
      deliveryFee + cartData.reduce((acc, item) => acc + item.price, 0)
    const discount = ((percentage / 100) * orderTotal).toLocaleString('en-US')
    const totaAfterDiscount = (
      orderTotal -
      (percentage / 100) * orderTotal
    ).toLocaleString('en-US')

    const message = `%0A%2A نوع الطلب %2A%3A توصيل %0A%2A رقم الطلب %2A%3A 0000${orderNumber} %0A%2A اسم العميل %2A%3A ${
      data?.name
    } %0A%2A رقم العميل %2A%3A ${data?.phone} %0A%2A عنوان العميل %2A%3A ${
      data?.address
    } %0A%2A موقع العميل %2A%3A https://www.google.com/maps?q=${
      fixedCenter ? fixedCenter?.lat : userLatLng.lat
    },${
      fixedCenter ? fixedCenter?.lng : userLatLng.lng
    } %0A---------------------------%0A${cartData
      .map((item) => {
        let size = ''
        let extras = ''
        let edits = ''
        if (item.size) {
          switch (item.size.name) {
            case 'small':
              size = 'صغير'
              break
            case 'medium':
              size = 'وسط'
              break
            case 'large':
              size = 'كبير'
              break
            default:
              size = ''
          }
        }
        if (item.extra && item.extra.length > 0) {
          extras = `%2A الإضافات %2A%3A %0A${item.extra
            .map((e) => `%20 %20 • ${e.name}`)
            .join('%0A')} %0A`
        }
        if (item.edits && item.edits.length > 0) {
          edits = `%2A التعديلات %2A%3A %0A${item.edits
            .map((e) => `%20 %20 • ${e}`)
            .join('%0A')}`
        }
        return `%2A الصنف %2A%3A ${item.name} %0A${
          size ? `%2A الحجم %2A%3A ${size} %0A` : ''
        }%2A الكمية %2A%3A ${item.quantity} %0A%2A السعر %2A%3A ${
          item.price
        } ريال ${extras ? extras : ''}${edits ? edits : ''}`
      })
      .join(
        '%0A---------------------------%0A'
      )}%0A---------------------------%0A%2A مبلغ الطلب %2A%3A ${total} ريال %0A%2A المجموع %2A%3A ${orderTotal.toLocaleString(
      'en-US'
    )} ريال %0A%2A ${
      percentage
        ? `الخصم %2A%3A ${discount} ريال %0A%2A المجموع بعد الخصم %2A%3A ${totaAfterDiscount} ريال %0A%2A`
        : ''
    }  وقت الطلب %2A%3A ${time} %0A%2A رسوم التوصيل خارج حي لبن يحدده المطعم %2A`

    if (userLatLng.lat === 0) {
      toast.info('قم بالسماح بتحديد الموقع لتحديد موقعك آلياً')
    } else {
      window.open(
        `https://api.whatsapp.com/send?phone=+966539706776&text=${message}`
      )
      toast.success('تم تأكيد الطلب بنجاح')
      navigate('/')
      setCartData([])
    }
  }

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        if (location) {
          const { latitude, longitude } = location.coords
          setUserLatLng({ lat: latitude, lng: longitude })
        } else {
          alert('قم بالسماح بتحديد الموقع لتحديد موقعك آلياً')
        }
      })
    }
  }, [])

  orderNumber++

  return (
    <div className='fixed inset-0'>
      <div className='w-full fixed z-[50] top-0 bg-white max-w-md mx-auto h-16 top-auto shadow-md py-1 flex items-center justify-between gap-2 dark:bg-gray-700'>
        <div className='col-span-9 grid grid-cols-12 justify-start items-center'>
          <Link
            to='/'
            className='col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2'
          >
            <img
              src={logo_light}
              alt='main logo'
              className=' w-[56px] h-[57px] dark:hidden'
            />
            <img
              src={logo_dark}
              alt='main logo'
              className=' w-[56px] h-[57px] hidden dark:block'
            />
            <h1 className='font-extrabold flex gap-1 flex-col text-md text-primaryGreen-500 dark:text-primaryGreen-200'>
              <span className='inline-block transform translate-y-1 mx-0.5 text-brown-400'>
                عنوان التوصيل
              </span>
              <span className='font-extrabold text-md text-primaryGreen-500 dark:text-primaryGreen-200 overflow-hidden'>
                {' '}
                Delivery Address
              </span>
            </h1>
          </Link>
        </div>
        <IoIosArrowBack
          className='w-10 h-10 ml-4 rounded-full text-main-900 hover:bg-main-900/80 hover:text-white dark:text-white transition p-2 cursor-pointer'
          title='رجوع'
          onClick={() => navigate(-1)}
        />
      </div>

      <div className='relative max-w-md h-[calc(100vh-150px)] pt-[64px] md:h-[calc(100vh-60px)] w-full overflow-hidden flex flex-col'>
        <MyGoogleMap
          latlng={userLatLng}
          fixedCenter={fixedCenter}
          setFixedCenter={setFixedCenter}
        />
        <form
          className='fixed max-w-md bottom-0 w-full flex flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] animateItems dark:bg-gray-700'
          onSubmit={handleSubmit}
        >
          <div className='flex flex-col text-sm'>
            <label htmlFor='name' className='relative'>
              <input
                type='text'
                required
                id='name'
                name='name'
                autoComplete='off'
                maxLength='25'
                enterkeyhint="next"
                className={`outline-none peer w-full border-b-2 dark:bg-gray-700 dark:text-white focus:border-none h-10 mt-1 px-6 ${
                  formErrors?.name ? 'border-red-500' : ''
                }`}
              />
              <AiOutlineUser className='absolute right-0 top-[50%] translate-y-[-50%] text-gray-400 text-xl peer-focus:text-main-900' />
              <div className='absolute bottom-0 bg-main-900 h-0.5 w-0 transition-all duration-500 peer-focus:w-full origin-center'></div>
              <span className='peer-focus:text-main-900 hidden peer-invalid:inline peer-focus:-translate-y-5 peer-focus:translate-x-2 peer-focus:scale-75 absolute text-black/50 right-0 top-3.5 mx-6 px-0.5 bg-white z-20 transition duration-200 dark:bg-gray-700 dark:text-gray-500'>
                اسم العميل
              </span>
            </label>
          </div>
          <div className='flex flex-col text-sm'>
            <label htmlFor='phone' className='relative'>
              <input
                type='number'
                required
                id='phone'
                name='phone'
                autoComplete='off'
                maxLength='25'
                pattern='[0-9]*'
                className={`outline-none peer border-b-2 focus:border-none dark:bg-gray-700 dark:text-white w-full h-10 mt-1 px-6 ${
                  formErrors?.phone ? 'border-red-500' : ''
                }`}
              />
              <RiWhatsappFill className='absolute right-0 top-[50%] translate-y-[-50%] text-gray-400 text-lg peer-focus:text-main-900' />
              <div className='absolute bottom-0 bg-main-900 h-0.5 w-0 transition-all duration-500 peer-focus:w-full origin-center'></div>
              <span className='peer-focus:text-main-900 hidden peer-invalid:inline peer-focus:-translate-y-5 peer-focus:translate-x-2 peer-focus:scale-75 absolute text-black/50 right-0 top-3.5 mx-6 px-0.5 bg-white z-20 transition duration-200 dark:bg-gray-700 dark:text-gray-500'>
                رقم التواصل
              </span>
            </label>
          </div>
          <div className='flex flex-col text-sm'>
            <label htmlFor='address' className='relative'>
              <input
                type='text'
                required
                id='address'
                name='address'
                autoComplete='off'
                maxLength='25'
                enterkeyhint="send"
                className={`outline-none peer w-full border-b-2 dark:bg-gray-700 dark:text-white focus:border-none h-10 mt-1 px-6 ${
                  formErrors?.address ? 'border-red-500' : ''
                }`}
              />
              <MdOutlinePlace className='absolute right-0 top-[50%] translate-y-[-50%] text-gray-400 text-lg peer-focus:text-main-900' />
              <div className='absolute bottom-0 bg-main-900 h-[0.15rem] w-0 transition-all duration-500 peer-focus:w-full origin-center'></div>
              <span className='peer-focus:text-main-900 hidden peer-invalid:inline peer-focus:-translate-y-5 peer-focus:translate-x-2 peer-focus:scale-75 absolute text-black/50 right-0 top-3.5 mx-6 px-0.5 bg-white z-20 transition duration-200 dark:bg-gray-700 dark:text-gray-500'>
                وصف العنوان
              </span>
            </label>
          </div>

          <button className='bg-main-900 transition text-white py-2 w-full rounded-full'>
            إرسال الطلب
          </button>
        </form>
        {modalOn ? (
          <Modal setModalOn={setModalOn} title='عفواً'>
            <div className='flex items-center justify-center text-center flex-col gap-4'>
              <MdLocationOn className='text-5xl text-main-900' />
              <p>عفواً، لا نستطيع التوصيل لأي موقع أبعد من 100 كيلومترات</p>
              <button onClick={() => setModalOn(false)}>إغلاق</button>
            </div>
          </Modal>
        ) : null}
      </div>
    </div>
  )
}
export default Transport
