/*global google*/

import { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { IoIosArrowBack } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom'
import { GoogleMap, useLoadScript, Marker, Autocomplete } from '@react-google-maps/api'
import { useForm } from 'react-hook-form'
import usePlaceAutocomplete, { getGeocode, getLatLng, } from 'use-places-autocomplete'
import { BiSearch, BiTargetLock } from 'react-icons/bi'
import { restaurants } from '../data'
import { useGlobalContext } from '../context'
import logo_light from '../img/logo.png'
import logo_dark from '../img/logo2.png'
import marker from '../img/marker2.svg'
import marker2 from '../img/marker.svg'
import point from '../img/point.png'
import Loader from './Loader'

const ReciveFromStore = () => {
  const [resLatLng, setResLatLng] = useState()
  const [userLatLng, setUserLatLng] = useState({
    lat: 24.621052,
    lng: 46.54662,
  })
  const [nearestRes, setNearestRes] = useState()
  const [value, setValue] = useState(0)
  const [storeName, setStoreName] = useState('')

  const activeRef = useRef(null)
  const navigate = useNavigate()

  // compare distance between restauran and user
  const calcCrow = (lat1, lon1, lat2, lon2) => {
    var R = 6371 // km
    var dLat = toRad(lat2 - lat1)
    var dLon = toRad(lon2 - lon1)
    var latitude1 = toRad(lat1)
    var latitude2 = toRad(lat2)

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(latitude1) *
        Math.cos(latitude2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c
    return d
  }
  const toRad = (Value) => {
    return (Value * Math.PI) / 180
  }

  // Find the distnce between restaurant and user
  const storeUserDistance = (storeLat, storeLng) => {
    return calcCrow(
      storeLat, // restaurant latitude
      storeLng, // restaurant longitude
      userLatLng.lat,
      userLatLng.lng
    )
  }

  // Get User location
  useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((location) => {
        if (location) {
          const { latitude, longitude } = location.coords
          const resCoords = restaurants.map((res) => res.coords)
          const resUserDistances = resCoords.map((coords) =>
            calcCrow(coords.lat, coords.lng, latitude, longitude)
          )
          const nearestDistance = Math.min(...resUserDistances)

          const nearest = restaurants.find(
            (res) =>
              calcCrow(res.coords.lat, res.coords.lng, latitude, longitude) ===
              nearestDistance
          )
          const nearestIndex = restaurants.findIndex(
            (res) => res.id === nearest.id
          )
          handleResClick(nearest, nearestIndex)
          setNearestRes(nearest.coords)
          setUserLatLng({ lat: latitude, lng: longitude })
        } else {
          alert('قم بالسماح بتحديد الموقع لتحديد موقعك آلياً')
        }
      })
    }
  }, [])

  // MESSAGE
  let orderNumber = 1
  const { cartData, setCartData, percentage } = useGlobalContext()
  const total = cartData
    .reduce((acc, item) => acc + item.price, 0)
    .toLocaleString('en-US')
  const date = new Date(Date.now())
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? 'مساءً' : 'صباحاً'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const time = `${hours}:${minutes} ${ampm}`

  const orderTotal = cartData.reduce((acc, item) => acc + item.price, 0)
  const discount = ((percentage / 100) * orderTotal).toLocaleString('en-US')
  const totaAfterDiscount = (
    orderTotal -
    (percentage / 100) * orderTotal
  ).toLocaleString('en-US')

  const handleFormSubmit = (e) => {
    e.preventDefault()

    if (!storeName) {
      toast.error('برجاء اختيار الفرع')
      return
    }

    const message = `%0A*نوع الطلب* : إستلام من الفرع %0A*اسم الفرع* : ${storeName} %0A---------------------------%0A${cartData
    .map((item) => {
      let size = '';
      let extras = '';
      let edits = '';
      if (item.size) {
        switch (item.size.name) {
          case 'small':
            size = 'صغير';
            break;
          case 'medium':
            size = 'وسط';
            break;
          case 'large':
            size = 'كبير';
            break;
          default:
            size = '';
        }
      }
          if (item.extra && item.extra.length > 0) {
            extras = `%0A*الإضافات* : %0A%20%20%20%20${item.extra
              .map((e) => `• ${e.name}`)
              .join('%0A%20%20%20%20')}`;
      }
      if (item.edits && item.edits.length > 0) {
        edits = `*التعديلات* : %0A%20%20%20%20${item.edits
          .map((e) => `• ${e}`)
          .join('%0A%20%20%20%20')}`;
      }
      return `*الصنف* : ${item.name} %0A${
        size ? `*الحجم* : ${size} %0A` : ''
      }*الكمية* : ${item.quantity} %0A*السعر* : ${
        item.price
      } ريال ${extras ? extras : ''}${edits ? edits : ''}`;
    })
    .join(
      '%0A---------------------------%0A'
    )}%0A---------------------------%0A%0A*مبلغ الطلب* : ${total} ريال %0A*المجموع* : ${orderTotal.toLocaleString(
    'en-US'
  )} ريال %0A${
    percentage
      ? `*الخصم* : ${discount} ريال %0A*المجموع بعد الخصم* : ${totaAfterDiscount} ريال %0A*`
      : ''
  }*وقت الطلب* : ${time}`;
    orderNumber++

    window.open(
      `https://api.whatsapp.com/send?phone=+966539706776&text=${message}`
    )
    toast.success('تم تأكيد الطلب بنجاح')
    navigate('/')
    setCartData([])
  }

  const handleResClick = (item, index) => {
    setValue(index)
    setResLatLng(item.coords)
    setStoreName(item.name)
    mapRef?.current?.panTo(item.coords)
  }
  

  // Center selected store
  useEffect(() => {
    const resContainer = document.getElementById('resContainer')
    resContainer?.scrollTo({
      behavior: 'smooth',
      top: activeRef.current.offsetTop - 20,
    })
  }, [value])

  const mapRef = useRef()

  const { register, watch, handleSubmit, setValue: setInputValue } = useForm()

  const { value: mapValue, setValue: setMapValue } = usePlaceAutocomplete()

  const onSubmit = (data) => setMapValue(data?.search)

  // center will be user location of exist if not restaurant location
  const center = useMemo(
    () => (resLatLng ? resLatLng : nearestRes),
    [nearestRes, resLatLng]
  )

  // MAP Attributes
  const mapStyles = useMemo(
    () => [
      {
        styles: [
          {
            featureType: 'poi.business',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'on' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ visibility: 'off' }],
      },
    ],
    []
  )
  const options = useMemo(
    () => ({
      styles: mapStyles,
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      gestureHandling: 'greedy',
    }),
    [mapStyles]
  )
  const onLoad = useCallback((map) => (mapRef.current = map), [])
  const libraries = useMemo(() => ['places'], [])

  // Search
  useEffect(() => {
    if (!mapValue) return

    const getResults = async () => {
      const results = await getGeocode({ address: mapValue })
      const { lat, lng } = getLatLng(results[0])

      mapRef.current.panTo({ lat, lng })
      setInputValue('search', '')
    }

    getResults()
  }, [mapValue, setInputValue])

  // Change location to restautant when change
  useEffect(() => {
    mapRef?.current?.panTo(resLatLng)
  }, [resLatLng])

  // Map Config
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCsE5KDJqjPpbTHsQFqSjnJHclQuCBw8c4',
    libraries,
    language: 'ar',
    region: 'SA',
  })

  if (!isLoaded) return <Loader />

  return (
    <div className='fixed inset-0'>
      <div className='w-full fixed z-[50] top-0 bg-white max-w-md mx-auto h-16 top-auto shadow-md py-1 flex items-center justify-between gap-2 dark:bg-gray-700'>
        <div className='col-span-9 grid grid-cols-12 justify-start items-center'>
          <Link
            to='/'
            className='col-span-10 pr-4 text-md font-semibold text-gray-500 dark:text-white overflow-y-hidden flex items-center cursor-pointer gap-2'
          >
            <img
              src={logo_light}
              alt='main logo'
              className=' w-[56px] h-[57px] dark:hidden'
            />
            <img
              src={logo_dark}
              alt='main logo'
              className=' w-[56px] h-[57px] hidden dark:block'
            />
            <h1 className='font-extrabold flex gap-1 flex-col text-md text-primaryGreen-500 dark:text-primaryGreen-200'>
              <span className='inline-block transform translate-y-1 mx-0.5 text-brown-400'>
                استلام من الفرع
              </span>
              <span className='font-extrabold text-md text-primaryGreen-500 dark:text-primaryGreen-200 overflow-hidden'>
                {' '}
                Branch Pickup
              </span>
            </h1>
          </Link>
        </div>
        <IoIosArrowBack
          className='w-10 h-10 ml-4 rounded-full text-main-900 hover:bg-main-900/80 hover:text-white dark:text-white transition p-2 cursor-pointer'
          title='رجوع'
          onClick={() => navigate(-1)}
        />
      </div>

      <div className='relative max-w-md pt-[64px] h-[calc(100vh-150px)] md:h-[calc(100vh-20px)] w-full overflow-hidden flex text flex-col'>
        {/* <StoreMap latlng={nearestRes} resLatLng={resLatLng} /> */}
        <GoogleMap
          center={center}
          mapContainerClassName='w-full h-full fixed top-0'
          zoom={15}
          options={options}
          onLoad={onLoad}
          gestureHandling={{ greedy: true }}
        >
          {restaurants.map((res, i) => (
            <Marker
              position={res.coords}
              key={res.id}
              onClick={() => handleResClick(res, i)}
              icon={{
                url: value === i ? marker2 : marker,
                scaledSize:
                  value === i
                    ? new google.maps.Size(50, 60)
                    : new google.maps.Size(40, 50),
              }}
            />
          ))}
          <Marker
            position={userLatLng}
            icon={{
              url: point,
              scaledSize: new google.maps.Size(15, 15),
            }}
          />
          {/* Search */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='absolute shadow-md z-40 top-4 left-4 flex items-center overflow-hidden justify-center bg-white rounded-full text-gray-700 group'
          >
            <Autocomplete onPlaceChanged={() => console.log(watch('search'))}>
              <input
                type='text'
                dir='ltr'
                autoComplete='off'
                {...register('search')}
                className={`w-0 group-hover:w-40 focus:w-40 active:w-40 transition-all outline-none ${
                  watch('search') && 'w-40'
                }`}
              />
            </Autocomplete>
            <button
              type='submit'
              className='w-8 h-8 p-1 shadow-md flex items-center justify-center'
            >
              <BiSearch />
            </button>
          </form>

          {/* Target */}
          <BiTargetLock
            className='absolute z-40 top-48 left-4 w-8 h-8 p-1 flex shadow-md items-center justify-center bg-white rounded-full text-gray-700'
            onClick={() => mapRef.current.panTo(userLatLng)}
          />
        </GoogleMap>
        <form
          className='fixed max-w-md bottom-0 w-full dark:bg-gray-700 flex flex-col justify-end gap-3 px-4 rounded-tr-[35px] rounded-tl-[35px] z-[401] py-4 bg-white shadow-[0_1px_6px_rgba(0,0,0,0.5)] animateItems'
          onSubmit={handleFormSubmit}
        >
          {/* Restaurants */}
          <div
            className='flex flex-col gap-4 lg:h-[120px] h-[130px] overflow-y-auto'
            id='resContainer'
          >
            {restaurants.map((res, i) => (
              <div
                className={`p-2 rounded-md bg-gray-100 flex flex-col gap-2 cursor-pointer border-2 ${
                  value === i
                    ? 'border-main-900 activeRes'
                    : 'border-transparent'
                }`}
                ref={value === i ? activeRef : null}
                onClick={() => handleResClick(res, i)}
                key={res.id}
              >
                <input
                  type='radio'
                  className='hidden'
                  name='store'
                  value={res.name}
                />
                <div className='flex items-center justify-between'>
                  <h4
                    className={`text-lg font-semibold ${
                      res.open ? 'text-gray-800' : 'text-gray-400'
                    }`}
                  >
                    {res.name}
                  </h4>
                  <p
                    className={`text-xs font-semibold ${
                      res.open ? 'text-main-900' : 'text-gray-600'
                    }`}
                  >
                    {storeUserDistance(res.coords.lat, res.coords.lng).toFixed(
                      2
                    )}{' '}
                    كم
                  </p>
                </div>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center gap-2'>
                    <AiOutlineClockCircle
                      className={`text-lg ${
                        res.open ? 'text-green-500' : 'text-red-500'
                      }`}
                    />
                    <p className='text-sm text-gray-500'>
                      {res.open ? 'مفتوح' : 'مغلق'}
                    </p>
                  </div>
                  {res.open24h ? (
                    <p className='text-main-900 text-xs '> 5:00 ص - 1:00 ص</p>
                  ) : null}
                </div>
                <p className='text-sm text-gray-700'>{res.addrress}</p>
              </div>
            ))}
          </div>
          <button className='bg-main-900 font-semibold text-white py-2 w-full rounded-full'>
            استلام من هذا الفرع
          </button>
        </form>
      </div>
    </div>
  )
}

export default ReciveFromStore
